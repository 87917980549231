<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- Bookmarks Container -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <search-bar />
      <cart-dropdown />
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import Bookmarks from "./components/Bookmarks.vue";
import SearchBar from "./components/SearchBar.vue";
import DarkToggler from "./components/DarkToggler.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";

import axios from "axios";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    //Bookmarks,
    SearchBar,
    DarkToggler,
    // NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  created() {
    this.LoadUser();
  },
  methods: {
    LoadUser() {
      var config = {
        method: "get",
        url:
          "https://api.worldappeal.org.uk/api/Users/" +
          this.$store.state.userData.userID,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data);
          let data = res.data.data;
          if (data == "NotFound") {
            this.logout();
          } else {
            if (data.status != "active" || !data.userRights) this.logout();
            else {
              let rights = JSON.parse(data.userRights);
              this.$store.commit("setRights", rights);
            }
          }
          // this.items = res.data.data.filter((el) => el.id != 1);
        })
        .catch(function(error) {
          console.log(error);
          this.logout();
        });
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);
      this.$store.commit("setRights", []);
      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>
